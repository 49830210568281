.divider-points {
  width: 100px;
  height: 1px;
  background: $gold;
  margin: 20px 15px;
  @media only screen and (min-width: 1400px) {
    margin: 40px 15px;
  }
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: $gold;
    top: -2px;
    left: -10px;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: $gold;
    top: -2px;
    right: -10px;
  }
  &.white {
    background: white;
    &:before {
      background: white;
    }
    &:after {
      background: white;
    }
  }
  &.purple {
    background: $purple;
    &:before {
      background: $purple;
    }
    &:after {
      background: $purple;
    }
  }
  &.dark-purple {
    background: $dark-purple;
    &:before {
      background: $dark-purple;
    }
    &:after {
      background: $dark-purple;
    }
  }
  &.center-it {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  &.left-only {
    margin-left: 10px !important;
  }
}
@import "variables";

.form-spinner {
    display: none;
}

div.user-alerts.alert-dismissable {
    margin-top: 18px;
}

.login-layout {
    height: 572px;
    max-width: 490px;
    margin: 0 auto;
    text-align: center;
}

.user-tab {
    background-color: $dark-purple;
    padding: 23px !important;
    color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    a, a:selected {
        border: 1px solid red !important;
        text-decoration: none !important;
        padding: 0 0 -10px !important;
    }

    &.active {
        background-color: $gold;
        color: $dark-purple;
    }

    a.active {
        background-color: $gold;
        color: $dark-purple;
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
    }
}

.user-table {
    width:98%;
    min-height:400px;
    border: 0 1px 0 1px solid #A9A9A9 !important;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "global/variables";
@import "global/transition";
@import "global/flexbox";
@import "global/global-mixins";
@import "global/base";
@import "global/flex-table";

@import "global/form";
@import "global/tab-group";
//@import "global/grid-items";
//@import "global/pagination";
@import "global/user_base";
@import "global/divider_points";
//@import "global/glyphicons";

//@import "global/header/header";
//@import "global/menu";
//@import "global/multi-item-carousel";
//@import "global/popups/popup_overlay";

//@import "components/banner-main";
//@import "components/category/category-banner";
//@import "components/homepage_press";
//@import "components/homepage/newsletter-row";
//@import "components/widgets/products_search_list";

//@import "components/contact/banner";
//@import "components/about/banner";
//@import "components/about/flexslider";
//@import "components/about/grid";
//@import "components/about/maps";

//@import "components/help-pages";
//@import "components/live-metal-prices";

//@import "components/basket/basket";
//@import "components/basket/basket-banner";
//@import "components/basket/basket-items";
//@import "components/checkout/checkout";

//@import "components/blog/index";
//@import "components/blog/new-index";
//@import "components/category/index";
//@import "components/product/index";

//@import "components/blog/view/blog-inner";

//@import "components/sale";
//@import "components/page-error";
//@import "components/user/index";
//@import "components/user/accounts_banner";
//@import "components/cms-page";

//@import "components/widgets/review-badge";
//@import "components/widgets/review-full";
//@import "components/widgets/review-mini";
//@import "components/widgets/review-carousel";

//@import "components/live-metal-prices/index";
//@import "components/live-metal-prices/form";
//@import "components/live-metal-prices/summary";
@import "components/live-metal-prices/live-chart";
//@import "components/live-metal-prices/live-table";
//@import "components/live-metal-prices/price-alerts";

//@import "components/investment-calculator";
//@import "components/metal-accounts";

//@import "components/webchat";

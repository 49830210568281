html {
    font-size: 16px!important;
}

.container {
    @include breakpoint(XLG) {
        width: 1360px;
    }
}
.tw-container {
    .container-inner {
        @include breakpoint(SM) {
            width: 95%;
            margin: 0 auto;
        }
        @include breakpoint(XLG) {
            width: 90%;
        }
    }
}

.fullheight {
    height: 100%;
}

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0 !important;
}

.ttu {
    text-transform: uppercase;
}

.display-table {
    display: table;
    width: 100%;
    height: 100%;
    .display-table-cell {
        display: table-cell;
        vertical-align: middle;
    }
}

p {
    margin: 0;
}

a {
    color: $gold;
    @include transition(0.5s);
    &:hover, &:focus, &:active {
        color: $purple;
    }
}

header > div, header > .notice-bar {
    display: none;
}

.nav-primary > li {
    display: none;

    .sub {
        display: none;
    }
}

.multi-item-carousel {
    display: none;
}

.carousel-control {
    display: none;
}

.offer-banner {
    a {
        display: flex;
        justify-content: center;
    }

    &.full-width img {
        width: 100%;
    }

    img {
        width: 90%;
    }
}

img {
    mix-blend-mode: multiply;

    &.brand-logo, &.brand, &.background_image {
        mix-blend-mode: normal;
    }
}

.alert-dismissable .close,
.alert-dismissible .close {
    min-width: 0;
}

button, input[type="submit"] {
    width: auto;
    min-width: 175px;
    color: white;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.05em;
    border-width: 0;
    text-transform: uppercase;
    border-radius: 10px;
    padding: 12px 15px;
    @include transition(0.5s);
    @include font(reg);
    &:hover, &:focus {
        outline: none;
        text-decoration: none;
    }
    &.btn-max-width {
        max-width: 175px;
    }
    &.btn-gold {
        background-color: $gold;
        color: $dark-purple;
        &:hover, &:focus {
            opacity: 0.8;
        }
        &.stand-out {
            color: white;
        }
        &.hvr-underline-from-center {
            &:before {
                background-color: white;
            }
        }
    }
    &.btn-purple {
        background-color: $purple;
        color: white;
        &:not(.btn-disabled):hover, &:not(.btn-disabled):focus {
            color: $gold;
        }
        &:not(.btn-disabled).hvr-underline-from-center {
            &:before {
                background-color: $gold;
            }
        }
        &.btn-disabled.hvr-underline-from-center {
            &:before {
                background-color: $purple;
            }
        }
    }
    &.btn-dark-purple {
        background-color: $dark-purple;
        &:hover, &:focus {
            color: $gold;
        }
        &.hvr-underline-from-center {
            &:before {
                background-color: $gold;
            }
        }
    }
    &.btn-silver {
        background-color: $silver;
        color: $dark-purple;
        &:hover, &:focus {
            color: $purple;
        }
        &.hvr-underline-from-center {
            &:before {
                background-color: $purple;
            }
        }
    }
    &.btn-mid-grey {
        background-color: $mid-grey;
        color: $purple;
        &:hover, &:focus {
            color: $dark-purple;
        }
        &.hvr-underline-from-center {
            &:before {
                background-color: $purple;
            }
        }
    }
    &.btn-white {
        background-color: $white;
        color: $dark-purple;
        box-shadow: 0 0 3px rgba(black, 0.2);
        &:hover, &:focus {
            color: $purple;
        }
        &.hvr-underline-from-center {
            &:before {
                background-color: $dark-purple;
            }
        }
    }
    &.btn-text {
        background: none;
        color: $dark-purple;
        &:hover, &:focus {
            text-decoration: underline;
        }
        &.hvr-underline-from-center {
            &:before {
                content: none;
            }
        }
    }

    .btn-icon + .btn-text,
    .btn-text + .btn-icon {
        margin-left: 10px;
    }
}

.select-wrap {
    select::-ms-expand {
        display: none;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #fff;
        background-image: url('/images/select-caret.png');
        background-position: center right 15px;
        background-repeat: no-repeat;
        border-radius: 0;
        color: $purple;
        position: relative;
        @include font(reg);
        outline: none;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@import "text_overflow";

body {
    font-family: 'proxima-nova', sans-serif;
    overflow-x: hidden;

    &.no-scroll {
        overflow: hidden;
    }
}

[contenteditable=true]:empty:before {
    content: attr(placeholder);
    display: block; /* For Firefox */
}

.zoomWindowContainer {
    width: 100px !important;
}

.zoomLens {
    background-color: white;
}

.divider {
    width: 100%;
    height: 1px;
    background: #e8e7ea;
    margin: 30px 0;

    &.small {
        margin: 15px 0;
    }

    &.no-margin {
        margin: 0;
    }

    &.side-padded {
        width: auto;
        margin-left: 30px;
        margin-right: 30px;

        &.small {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    &.section_divider {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
        height: 2px;
        background-color: $gold;
    }

    &.blank_space {
        background: none !important;
        margin: 20px !important;

        &-15px {
            background: none !important;
            margin: 15px !important;
        }

        &-10px {
            background: none !important;
            margin: 10px !important;
        }
    }

    &.vertical {
        height: 100%;
        width: 1px;
    }
}

.totals span.divider {
    display: block;
}

#product-slider-main, #product-slider-controlled {
    display: none;
}

.message-wrapper {
    @include breakpoint(LG) {
        text-align: center;
    }
    .message {
        color: $gold;
        @include font(reg);
        text-transform: uppercase;
        &:not(:empty) {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        &.error {
            color: #D21919;
        }
    }
}

.products-single-widget {
    .buttons
    > button {
        @include breakpoint(SM-MAX) {
            margin:0.25rem auto;
            display: block;
        }
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
    }
    @include breakpoint(SM-MAX) {
        .js-quantity {
            margin: 0 auto;
            button {
                margin: 0 auto;
            }
        }
    }
    @include breakpoint(MD) {
        .js-quantity {
            padding: 0 10px 0;
            display: inline-flex;
            vertical-align: middle;
            max-height: 42px;
        }
        .message-wrapper {
            display: inline-flex;
            vertical-align: middle;
            .message {
                &:not(:empty) {
                    padding: 0 20px;
                }
            }
        }
    }
}

.page_background {
    background-color: #E5E5E5;
}

.container-shadow {
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.33);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.33);
    background-color: #fff;
}

.v-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.animate-on-view {
    opacity: 0.001;
}

.cb-custom {
    position: relative;

    input[type=checkbox] {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        &:checked {
            + label {
                &:before {
                    background-color: $gold;
                    border-color: $gold;
                }
            }
        }

        &:focus {
            + label {
                &:before {
                    box-shadow: 0 0 3px #ccc;
                }
            }
        }
    }

    label {
        padding-left: 25px;
        margin-bottom: 0;
        @include font(reg);
        line-height: 16px;
        cursor: pointer;
        @extend .no-select;
        position: relative;

        &:hover {
            &:before {
                box-shadow: 0 0 3px #ccc;
            }
        }

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 15px;
            height: 15px;
            border: 1px solid rgba(black, 0.25);
            background-color: #fff;
            content: "";
            transition: background-color 0.3s ease, box-shadow 0.3s ease;
        }
    }
}

.section {
    &.bottom-padded {
        padding-bottom: 50px;
    }
}

/* fade image in after load */
.lazyload:not(.lazyload-no-fade),
.lazyloading:not(.lazyload-no-fade) {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 200ms;
}

.lazyload-no-fade {
    opacity: 1;
}

.section_title {
    font-family: 'baskerville', serif;
    font-size: 1.5rem;
    color: $purple;

    &.extra-margin {
        margin-top: 15px;
        margin-bottom: 30px;
    }
}

.entry_columns {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
}

.carousel {
    display: flex !important;
    justify-content: center !important;
    width: 100%;
}

.row.display-flex {
    display: flex;
    flex-wrap: wrap;
}

.row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.below_500px-col {
    @media (max-width: 500px) {
        &-12 {
            width: 100%;
        }

        &-6 {
            width: 50%;
        }
    }
}

.col-lg-10 {
    @media (min-width: $screen-lg) {
        float: left;
        position: relative;
        min-height: 1px;
        width: 83.33333%
    }
}

.col-md {
    @media (min-width: $screen-md) {
        float: left;
        position: relative;
        min-height: 1px;

        &-15 {
            width: 20%;
        }

        &-45 {
            width: 80%;
        }
    }
}

.lg_only {
    @media (min-width: $screen-lg) {
        &-col {
            float: left;
            position: relative;
            min-height: 1px;

            &-15 {
                width: 20%
            }

            &-2 {
                width: 16.66667%
            }

            &-10 {
                width: 83.33333%;
            }

            &-12 {
                width: 100%
            }
        }
    }
}
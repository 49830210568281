.form {
  .help-block {
    &:empty {
      display: none;
    }
  }
  .error-block {
    color: red;
    font-size: 12px;
    &:empty {
      display: none;
    }
  }
  .fields--pad {
    margin-top: 30px;
  }
  .column {
    padding: 30px;
    &.padding-xl {
      padding: 60px;
    }
    @include breakpoint(MD) {
      &.padding-md-xl {
        padding: 60px;
      }
    }
  }
  h2 {
    @include font(script);
    color: $dark-purple;
    letter-spacing: 0;
    margin: 0;
    font-size: 32px;
  }
  .field-wrap {
    margin-top: 15px;
    label{
      @include font(reg);
      font-weight: 600;
    }
    &.required {
      label:after {
        content: "*";
        margin-left: 2px;
        color: red;
      }
    }
    input[type='text'],
    input[type='password'],
    input[type='tel'],
    input[type='number'],
    input[type='email'],
    textarea,
    select {
      @include font(reg);
      padding: 10px 25px;
      width: 100%;
      border: 1px solid #ccc;
      &:hover, &:focus{
        border-color: $purple;
      }
      &:focus {
        outline: none;
      }
    }
    textarea {
      padding: 20px 25px;
      line-height: 18px;
      resize: none;
      overflow: auto;
      min-height: 100px;
    }
    .dropdowns {
      display: table;
      width: 100%;
      table-layout: fixed;
      .cell {
        display: table-cell;
        vertical-align: top;

        &:nth-child(1) {
          text-align: left;
        }

        &:nth-child(2) {
          text-align: center;
        }

        &:nth-child(3) {
          text-align: right;
        }

        input {
          width: 95%;
        }
      }
    }
  }
  .btn-submit {
    margin-top:20px;
  }
  @include breakpoint(MD) {
    .seperate-vertical-not-xs:before {
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0);
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      opacity: 0.4;
    }
  }
  @include breakpoint(SM-MAX) {
    .seperate-horizontal-xs:after {
      background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
      background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
      background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=1);
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      opacity: 0.4;
    }
  }
  .password-label {
    margin-top: 15px;
  }
  .password-information {
    background-color: #d0d0d0;
    padding: 10px;
    font-size: 11px;
    margin-bottom: -10px;
    &-popup {
      background-color: #413a5f;
      margin-bottom: -15px;
    }
    &-account {
      background-color: #eaeaea;
      margin-bottom: -15px;
    }
  }
  .kv-container {
    .form-control.input {
      height: 42px;
      border-radius: 0;
      outline: 0;
    }
  }
  .kv-meter-container {
    margin-top: -4px;
    width: 100%;
  }
  .kv-verdict .label {
    width: 100%;
    font-size: 10px;
    border-radius: 0;
    &.label-primary {
      background-color: #93b863;
    }
    &.label-info {
      background-color: #c5d544;
    }
    &.label-default {
      background-color: #d9534f;
    }
  }
}

.field-register-form-recaptcha2,
.field-contact-recaptcha2 {
  padding-top: 20px;
}
.field-register-form-recaptcha2 label,
.field-contact-recaptcha2 label,
.field-register-form-recaptcha3 label,
.field-contact-recaptcha3 label,
.field-newslettersignupform-recaptcha3 label,
.field-newslettersignupform-recaptcha2 label {
  display: none;
}

.grecaptcha-badge {
  display: none !important;
}

.recaptcha-privacy {
  color: darkgrey;
  font-size: 10px;
}
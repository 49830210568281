@import "../../global/variables";

.page-live-metal-prices {
  text.highcharts-credits,
  g.highcharts-range-selector-buttons {
    display: none;
  }
  .live-chart-row {
    .live-chart {
      float: left;
      width: 100%;
      height: 100%;
      padding: 20px 15px;
      @include breakpoint(XS) {
        padding: 20px 0;
      }
      position: relative;

      @include breakpoint(LG) {
        width: 62%;
        padding: 0 25px 0;
      }

      @include breakpoint(XLG) {
        padding: 0 25px 0;
      }

      .content {
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        @include breakpoint(MD-MAX) {
          top: auto;
          transform: none;
        }
        h2 {
            font-size: 21px;
            @include font(script);
        }
        .opening-times {
            text-align: center;
            color: $purple;
            @include font(reg);
            h2 {
                color: $dark-purple;
            }
        }
      }

      .chart-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(black, 0.5);
        display: none;

        img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }
      }
    } // .live-chart
  } // .live-chart-row
  .alerts__table {
    .error {
      color: #a94442;
    }
  }
} // .page-live-metal-prices

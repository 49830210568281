.tab-group {
  @media screen and (min-width: 1400px) {
    padding: 0px
  }
  .tab-titles {
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        margin: 0;
        display: flex;
        @include font(reg);
        font-size: 13px;
        color: #7d7b87;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
        align-items: center;

        &:not(:last-child) {
          margin-right: 10px;
        }

        @include breakpoint(SM) {
          &:not(:last-child) {
            margin-right: 30px;
          }
        }

        &:before {
          background-color: $gold;
          height: 2px;
        }

        &.active {
          &:before {
            left: 0;
            right: 0;
            background-color: $purple;
          }
        }

        .logout {
          color: red;
        }

        a {
          color: inherit;
          text-decoration: none;
          display: block;
          height: fit-content;
          padding: 20px 0px 10px;

          @include breakpoint(SM) {
            padding: 20px 10px 10px;
          }
        }
      }
    }

    h4 {
      padding: 20px 0px 20px;
      margin: 0;
      display: inline-block;
      @include font(reg);
      font-size: 13px;
      color: #7d7b87;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 20px;
      }

      @include breakpoint(SM) {
        padding: 20px 10px 20px;

        &:not(:last-child) {
          margin-right: 50px;
        }
      }

      &:before {
        background-color: $gold;
        height: 2px;
      }

      &.active {
        &:before {
          left: 0;
          right: 0;
          background-color: $purple;
        }
      }
    }
  }

  .tab-content {
    float: left;
    width: 100%;
    margin: 25px 0;

    .content {
      display: none;

      &.active {
        display: block;
      }

      .blog-contents {
          .title {
              right: -118px;
          }
      }

      &:not(.cms-wrapper) {
        color: #919191;
        @include font(reg);
        font-size: 15px;
        letter-spacing: 0;
        line-height: 28px;
        overflow: auto;

        p, ul, ol {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        h1, h2, h3, h4, h5, h6 {
          @include font(script);
          color: $purple;
          font-weight: 100;
        }

        h1, h2 {
          font-size: 24px;
        }

        iframe[src*="youtube"] {
          display: block;
          margin: auto;
        }

        table {
          &:not(.table-quantity-breaks) {
            &:not(.table--fullwidth) {
              border: 1px solid #A9A9A9;
              width: auto;
            }
          }
          &:not(.account__details__table) {
            &:not(.table-quantity-breaks) {
              margin: 0 auto 20px;
              line-height: normal;
              border: none;
              th, td {
                padding: 10px;
                border: 1px solid #A9A9A9;
                font-size: 14px;
              }
            }
          }
        }

        .section {
          &:not(:last-child) {
            margin-bottom:40px;
          }
        }

        img {
          max-width: 100%;
          height: auto;
        }
      } // &:not(.cms-wrapper)

      &.cms-wrapper {
        .main {
          padding: 0;
        }
      }
    } // .content
  } // .tab-content
  &.tab-group-filled {
    .tab-titles {
      h4 {
        border: 1px solid #e8e8e8;
        background-color: #f9f9fa;
        margin-right: 0;
        padding: 20px;
        &.active {
          background-color: #ffffff;
          border-bottom: #ffffff;
          &:before {
            background-color: #ffffff;
          }
        }
      }
    }
    .tab-content {
      border: 1px solid #e8e8e8;
      margin:-1px 0 0;
      padding:20px;
    }
  }
  .tab-accordian {
    display: none
  }
  @include breakpoint(SM-MAX) {
    &.tab-group-accordian {
      .tab-titles {
        display: none;
      }
      .tab-accordian {
        @include font(reg);
        font-size: 13px;
        color: $dark-purple;
        text-transform: uppercase;
        text-align: center;
        display: block;
        &:not(:first-of-type) {
          border-top: 1px solid #e8e8e8;
        }
        &:not(:last-of-type) {
          border-bottom: 1px solid #e8e8e8;
        }
        background-color: #f9f9fa;
        margin-right: 0;
        padding: 20px;
        &.active {
          background-color: #ffffff;
          border-bottom: #ffffff;
          &:before {
            background-color: #ffffff;
          }
        }

        &:before {
          background-color: $purple;
        }
      }
      .tab-content {
        padding: 0;
      }
      .content {
        padding: 20px;
      }
    }
  }
} // .tab-group

.flex__table {
    border-left: 1px solid #efefef;
    border-top: 1px solid #efefef;

    .flex__row {
        @include flexbox();
    }

    .flex__title,
    .flex__cell {
        @include flexbox();
        padding: 0.625rem;
        border-right: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        &--center {
            @include justify-content(center);
        }
        @include flex(1 0);
        @include align-items(center);
    }

    @include breakpoint(SM-MAX) {
        &--flip-mobile {
            @include flexbox();
            .flex__row {
                @include flex(1 0 0);
                @include flex-direction(column);
            }
        }
    }
}